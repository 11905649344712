const LiveBg = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C2.89543 5 2 5.89543 2 7V21C2 22.1046 2.89543 23 4 23H18C19.1046 23 20 22.1046 20 21V17.7694L23.7889 19.8095C24.7882 20.3476 26 19.6238 26 18.4888V9.51148C26 8.37647 24.7882 7.65267 23.7889 8.19078L20 10.2309V7C20 5.89543 19.1046 5 18 5H4Z"
        fill="#F84D4F"
      ></path>
    </svg>
  )
}

export default LiveBg
